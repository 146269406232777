// import { useEffect, useState } from "react"
import {
  faPython,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from "@fortawesome/free-brands-svg-icons"
import Loader from "react-loaders"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./index.scss"
import Sidebar from "../Sidebar"

const About = () => {
  // const [letterClass, setLetterClass] = useState("text-animate")

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLetterClass("text-animate-hover")
  //   }, 3000)
  // }, [])

  return (
    <>
      <Sidebar />
      <div className="container about-page">
        <div className="text-zone">

          <h1>About Me</h1>
          <p>
            I am an innovative fullstack developer with experience in HTML5,
            Javascript, CSS3, React, Django, Python, NodeJS, Git, Express, PSQL, EJS,  and a plethora
            of other technologies and stacks.
          </p>
          <p align="LEFT">
            I have a background in client relations and understand the
            importance of serving my clients. My passion for business
            development in conjunction with programming allow me to find
            innovative solutions to my clients' needs. I relentlessly strive to
            create scalable web applications which dynamically tailor to the
            user’s needs.
          </p>
          <p>
            I believe that the every application needs to fill the user's needs
            as well as the business I am a creating the application for. If you are
            having issues turning your creative idea into a full scale product
            or application, I can build it. I currently am only doing freelance
            work; However, I am looking to fill a fulltime position as a
            software developer if you feel I am a good fit for your company.
            Feel free to contact me if you have any questions or just want to
            network.
          </p>
          

        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faPython} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
