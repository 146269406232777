import { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useRef } from "react"
import emailjs from "@emailjs/browser"
import "./index.scss"
import Sidebar from "../Sidebar"
import AnimatedLetters from '../AnimatedLetters'

const Contact = () => {
  const [letterClass, setLetterClass] = useState("text-animate")
  const form = useRef()

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover")
    }, 3000)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm("service_chfn65h", "template_is0cn1m", form.current, "-BYPWdd36WcpLgcNI")
      .then(
        () => {
          alert("Message successfully sent!")
          window.location.reload(false)
        },
        () => {
          alert("Failed to send the message, please try again")
        }
      )
  }

  return (
    <>
      <Sidebar />
      <div className="container contact-page">
        <div className="text-zone">
          <h1 className="page-title">Contact Me</h1>
          <AnimatedLetters
              letterClass={letterClass}
              strArray={['', '', '', '', '', '', '', ' ', '', '']}
              idx={15}
            />

          <p >
            I am interested in working for a company which requires a junior software
            developer who has experience in HTML5, CSS3, Javascript and utilizes
            the MERN stack. I am also very passionate about building user
            centric CRUD app's and am always open to freelance work. Feel free
            to email me in the form below if you have any questions or if you
            are looking for your next ambitious junior developer who is eager to
            learn new technologies.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact
