import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Loader from "react-loaders"
import AnimatedLetters from "../AnimatedLetters"
import LogoTitle from "../../assets/images/logo-s.png"
// import Logo from './Logo'
import "./index.scss"
import Sidebar from "../Sidebar"

const Home = () => {
  const [letterClass, setLetterClass] = useState("text-animate")

  const nameArray = [
    "M",
    "a",
    "t",
    "t",
    "h",
    "e",
    "w",
    "",
    "S",
    "u",
    "z",
    "u",
    "k",
    "i",
  ]
  const jobArray = [
    "W",
    "e",
    "b",
    " ",
    "D",
    "e",
    "v",
    "e",
    "l",
    "o",
    "p",
    "e",
    "r",
  ]

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover")
    }, 4000)
  }, [])

  return (
    <>
      <Sidebar />

      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <img
              src={LogoTitle}
              alt="JavaScript Developer Name, Web Developer Name"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>Frontend Developer</h2>

          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
          <a
            href="/assets/Matthew Suzuki Resume October 2022.pdf"
            download
            className="flat-button"
          >
            Resume
          </a>
          <Link to="/portfolio" className="flat-button">
            VIEW MY PROJECTS
          </Link>
        </div>
        {/* <Logo /> */}
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Home
